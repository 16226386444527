// site_worker/lps.js

import Swiper, { Navigation, Pagination } from 'swiper';

Swiper.use([Navigation, Pagination]);

// case studies
$(function(){
  $.get("/service-pw/lps/case_studies", function(data){
    $("#js-usageslider").html(data);

    const usageSlider = new Swiper ('#js-usageslider', {
      slidesPerView: 'auto',
      speed: 600,
      navigation: {
        nextEl: '#js-usageslider__next',
        prevEl: '#js-usageslider__prev',
      }
    });
  });
});
